/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { PaginatedResponse } from '@eagle/api-types';
import { AlertResponse, getThingFeatureCombinations, Thing, ThingType } from '@eagle/core-data-types';
import { DateRange, useAuthenticated } from '@eagle/react-common';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { useVideoFilters } from '../camera-content';
import { CameraDateOptions } from '../camera-content.types';
import { useVideoSegments } from './video-content-util';
import { VideoSegmentView } from './video-segment-view';

interface Props {
  bannerOpen: (data: string) => void;
  dateRange: CameraDateOptions;
  disableAutoScroll: boolean;
  disableBack?: boolean;
  disableForward?: boolean;
  handleBack?: (date: DateRange, minDateTime?: DateTime) => void;
  handleForward?: (date: DateRange, mobileLogic?: boolean) => void;
  handleGridLoaded: () => void;
  refresh: boolean;
  thing: Thing;
  thingType: ThingType;
}

export const VideoSegmentController: FC<Props> = ({
  bannerOpen,
  dateRange,
  disableAutoScroll,
  disableBack,
  disableForward,
  handleBack,
  handleForward,
  handleGridLoaded,
  refresh,
  thing,
  thingType,
}) => {
  const { axios } = useAuthenticated();
  const cameraFeature = thingType.features.find((feature) => feature.featureId === 'camera');
  const [cameraFeatures, setCameraFeatures] = useState(cameraFeature ? getThingFeatureCombinations(cameraFeature) : []);
  const { filters } = useVideoFilters();
  const videoData = useMemo(() => {
    return useVideoSegments(cameraFeatures, thing._id, dateRange, filters, axios);
  }, [cameraFeatures, dateRange, filters, refresh]);

  useEffect(() => {
    setCameraFeatures(cameraFeature ? getThingFeatureCombinations(cameraFeature) : []);
  }, [cameraFeature]);

  const alertData = useMemo(() => {
    const getThingAlerts = async (skip: number): Promise<AlertResponse[]> => {
      const response = await axios.get<PaginatedResponse<AlertResponse>>(`/api/v2/alert/thing/${thing._id}`, {
        params: {
          dateRangeStart: dateRange.startTime.toISO(),
          dateRangeFinish: dateRange.endTime.toISO(),
          filter: filters,
          skip,
        },
      });
      if (response.data.hasMore) {
        const nextThingAlerts = await getThingAlerts(response.data.items.length + skip);
        return response.data.items.concat(nextThingAlerts);
      }
      return response.data.items;
    };
    return getThingAlerts(0);
  }, [dateRange, filters, thing]);

  return (
    <VideoSegmentView
      alertData={alertData}
      bannerOpen={bannerOpen}
      dateRange={dateRange}
      disableAutoScroll={disableAutoScroll}
      disableBack={disableBack}
      disableForward={disableForward}
      features={cameraFeatures}
      handleBack={handleBack}
      handleForward={handleForward}
      handleGridLoaded={handleGridLoaded}
      thing={thing}
      videoData={videoData}
      refresh={refresh}
    />
  );
};
