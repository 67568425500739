import { SegmentData } from '@eagle/core-data-types';
import { ROUND, roundTimeToInterval, SEGMENT_MINUTES } from '@eagle/react-common';
import { AxiosInstance } from 'axios';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { FilterType } from '../camera-content';
import { RequestAvailable, RequestUpload } from './video-segment.types';

interface DateRange {
  endTime: DateTime;
  startTime: DateTime;
}

interface VideoPromise {
  data: Promise<SegmentData[]>;
  feature: string;
}

export const requestSegmentUpload = (data: RequestUpload, thingId: string, axios: AxiosInstance): Promise<unknown> => {
  const url = `/api/v1/video-segment/thing/${thingId}/featureTypeId/${encodeURIComponent(data.featureTypeId)}/feature/${encodeURIComponent(data.feature)}/request-upload`;
  const result = axios.post<unknown>(url, [{
    start: data.start,
    finish: data.finish,
  }]
  ).then((response) => {
    return response.data;
  });
  return result;
};

export const useAvailableSegments = (data: RequestAvailable, thingId: string, axios: AxiosInstance): Promise<unknown> => {
  const result = axios.post<unknown>(`/api/v1/video-segment/thing/${thingId}/feature/${encodeURIComponent(data.feature)}/query-availability`, null, {
    params: {
      dateRangeStart: data.start,
      dateRangeFinish: data.finish,
    },
  }).then((response) => {
    return response.data;
  });
  return result;
};

export const TimeFormatAccordion: FC<{ end: string; start: string; timeStart: Date }> = ({ end, start, timeStart }) => {
  if (start !== end) return <>{`${start} - ${end}`}</>;
  const startTimeOffset = DateTime.fromJSDate(timeStart).minus({ minutes: SEGMENT_MINUTES }).toJSDate();
  return <>{`${roundTimeToInterval(startTimeOffset, ROUND.UP).toLocaleString(DateTime.TIME_WITH_SECONDS)} - ${end}`}</>;
};

export const useVideoSegments = (features: string[], thingId: string, dateRange: DateRange, filters: Record<FilterType, string>[], axios: AxiosInstance): VideoPromise[] => {
  const cameraFilters: Record<string, string>[] = [];
  const statusFilters: Record<string, string>[] = [];
  filters.map((record) => Object.keys(record)[0] === 'status' ? statusFilters.push(record) : cameraFilters.push(record));
  const params = Object.assign(
    { dateRangeStart: dateRange.startTime.toJSDate(), dateRangeFinish: dateRange.endTime.toJSDate() },
    { 'filter': { '$and': [{ '$or': cameraFilters }, { '$or': statusFilters }] } }
  );

  return features.map((feature) => {
    const result = axios.get<SegmentData[]>(`/api/v1/video-segment/thing/${thingId}/feature/${encodeURIComponent(feature)}/list-availability`, {
      params,
    }).then((response) => {
      return response.data;
    });
    return {
      data: result,
      feature,
    };
  });
};
