import { AlertResponse } from '@eagle/core-data-types';
import { FormatTimestamp, getAlertTypeStyle, Nullable, useCustomRoutes } from '@eagle/react-common';
import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import { Button, IconButton, Popover, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref } from 'react-router-dom';
import { tableStyles } from './video-segment.styles';

interface Props {
  alert: AlertResponse;
}

export const AlertSegmentCell: FC<Props> = ({ alert }) => {
  const { alert: customRoutesAlert } = useCustomRoutes();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const alertHref = useHref(`/${customRoutesAlert}/${alert.alertId}`);
  const { t } = useTranslation(['track']);
  const { classes } = tableStyles();
  const theme = useTheme();
  const alertStyle = getAlertTypeStyle(alert, theme);
  const { alertTypeId, feature, featureTypeId } = alert;

  return <>
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setPopoverOpen(false)}
      open={popoverOpen}
      sx={{ zIndex: 1600 }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Stack direction="row">
        <Stack sx={{ margin: '20px' }}>
          <Typography>{t(`common:features.${feature}`)}</Typography>
          <Typography variant="body2">
            {t(`common:alert-descriptions.${featureTypeId}.${alertTypeId}.label`)}
          </Typography>
          <FormatTimestamp value={alert.occurred} />
        </Stack>
        <Button href={alertHref} sx={{ margin: '20px' }} variant="text">{t('track:page.camera-content.action.view-alert')}</Button>
      </Stack>
    </Popover>
    <IconButton
      onClick={(event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setPopoverOpen(true);
      }}
      size="small"
      sx={{
        mx: 0,
        my: '5px',
        p: 0,
        zIndex: 1,
      }}
    >
      <CircleNotificationsRoundedIcon fontSize="medium" sx={{ color: alertStyle.background, zIndex: 5 }} />
      <Box className={classes.alertCellBackground} sx={{ background: alertStyle.color }} />
    </IconButton>
  </>;
};
